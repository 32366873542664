// import ApplicationController from 'application_controller'
import { Controller } from "stimulus"

// export default class extends ApplicationController {
export default class extends Controller {
	connect(){
		console.log('Form features controller connected', this.element);
		$.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
			icons: {
				time: 'fas fa-clock',
				date: 'fas fa-calendar-alt',
				up: 'fas fa-arrow-up',
				down: 'fas fa-arrow-down',
				previous: 'fas fa-chevron-left',
				next: 'fas fa-chevron-right',
				today: 'fas fa-calendar-check-o',
				clear: 'fas fa-trash',
				close: 'fas fa-times'
			}
		});
		$('.datetimepicker').datetimepicker({
			keepOpen: true
		});
		$('.selectpicker').selectpicker();
		$('[data-toggle="table"]').bootstrapTable();
	}
}
