// import ApplicationController from 'application_controller'
import { Controller } from "stimulus"

// export default class extends ApplicationController {
export default class extends Controller {
    connect(){
	console.log('Disable controller connected', this.element);
    }

    disable(event) {
	const submitter = event.detail.formSubmission.submitter
	if (submitter.dataset.disableWith) {
	    submitter.setAttribute('disabled', true)
	    submitter.dataset.disablePreviousText = submitter.value
	    submitter.value = submitter.dataset.disableWith
	}
    }

    enable(event) {
	const submitter = event.detail.formSubmission.submitter
	if (submitter.dataset.disablePreviousText) {
	    submitter.removeAttribute('disabled')
	    submitter.value = submitter.dataset.disablePreviousText
	    delete submitter.dataset.disablePreviousText
	}
    }
}
