// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap";
import "@fortawesome/fontawesome-free/js/all"
require('components/bootstrap-table.min.js')
require('components/froala_editor.min.js')
require('moment')
require('tempusdominus-bootstrap-4')
require('css/bootstrap-table.min.css')
require('css/application.scss')
// require('../css/froala3.css')
// require('../css/froala3_dark.min.css')
require('chart.js')
require('chartkick')

Rails.start()
ActiveStorage.start()
global.Rails = Rails;

// document.addEventListener("turbo:click", () => {
// 	console.log('turbo:click fired');
// });
// document.addEventListener("turbo:before-fetch-request", () => {
// 	console.log('turbo:before-fetch-request fired');
// });
// document.addEventListener("turbo:before-fetch-response", () => {
// 	console.log('turbo:before-fetch-response fired');
// });
// document.addEventListener("turbo:submit-start", () => {
// 	console.log('turbo:submit-start fired');
// });
// document.addEventListener("turbo:submit-end", () => {
// 	console.log('turbo:submit-end fired');
// });
// document.addEventListener("turbo:before-render", () => {
// 	console.log('turbo:before-render fired');
// });
// document.addEventListener("turbo:visit", () => {
// 	console.log('turbo:visit fired');
// });
// document.addEventListener("turbo:render", () => {
// 	console.log('turbo:render fired');
// });
document.addEventListener("turbo:before-stream-render", () => {
	console.log('turbo:before-stream-render fired');
});
document.addEventListener("turbo:load", () => {
	console.log('turbo:load fired');
	$(window).trigger('load.bs.select.data-api');
	$.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
		icons: {
			time: 'fas fa-clock',
			date: 'fas fa-calendar-alt',
			up: 'fas fa-arrow-up',
			down: 'fas fa-arrow-down',
			previous: 'fas fa-chevron-left',
			next: 'fas fa-chevron-right',
			today: 'fas fa-calendar-check-o',
			clear: 'fas fa-trash',
			close: 'fas fa-times'
		}
	});
	$('.datetimepicker').datetimepicker({
		keepOpen: true
	});
	$('[data-toggle="table"]').bootstrapTable();
  setTimeout(function(){
    $('.selectpicker').selectpicker();
  }, 200);
});
window.addEventListener("DOMContentLoaded", () => {
	console.log("DOM ready");
});

import "controllers"
